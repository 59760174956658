import { Helmet } from 'react-helmet-async';

import { ProtectedRouteInterface } from '@/configs/routes/protected-routes';
import { APP_CONFIG } from '@/constants/app-config';
import { useTwin } from '@/lib/hooks/api/twin.hook';
import { useAuth } from '@/lib/hooks/useAuth';
import { useSettingsStore } from '@/store';

const AppRoute = ({ ...props }: ProtectedRouteInterface) => {
  const { component: Component, name, path } = props;
  const { isAuthenticated } = useAuth();
  const { settings } = useSettingsStore();
  const { twinData } = useTwin();
  const PersonalityName = twinData?.personalityName;

  const title = isAuthenticated
    ? path.startsWith('/chat/')
      ? `${PersonalityName ?? 'Chat'} | ${settings?.ui?.appName || APP_CONFIG.appName}`
      : name
        ? `${name} | ${settings?.ui?.appName || APP_CONFIG.appName}`
        : APP_CONFIG.appName
    : name
      ? `${name} | ${settings?.ui?.appName || APP_CONFIG.appName}`
      : APP_CONFIG.appName;

  return (
    <>
      <Helmet>
        <title>{title || 'Twin Protocol'}</title>
        <meta
          name="description"
          content="Sharing knowledge & wisdom to better the world"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0c9dd8" />
      </Helmet>
      <Component />
    </>
  );
};

export default AppRoute;
