import { createContext, useEffect, useMemo, useState } from 'react';

import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { AppKitNetwork, mainnet, polygonAmoy } from '@reown/appkit/networks';
import type { Provider } from '@reown/appkit/react';
import {
  createAppKit,
  useAppKitAccount,
  useAppKitNetworkCore,
  useAppKitProvider,
  useAppKitTheme,
  useDisconnect,
} from '@reown/appkit/react';
import { BrowserProvider, formatEther } from 'ethers';
import { toast } from 'sonner';

import { useAuth } from '@/lib/hooks/useAuth';
import useColors from '@/lib/hooks/useColors';
import { useSettingsStore } from '@/store';

const devMode = (import.meta.env.VITE_TEST_MODE === 'true') as boolean;
const projectId = import.meta.env.VITE_WALLET_PROJECT_ID ?? '';
const allowedChains: number[] = [mainnet.id, polygonAmoy.id];

// Define the Wallet Context Type
export interface WalletContextType {
  ethBalance: string | null;
  disconnect: () => void;
  ethersProvider: BrowserProvider | null;
  wrongNetwork: boolean;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

const WalletWrapper = ({ children }: { children: React.ReactNode }) => {
  const { settings, themeMode } = useSettingsStore();
  const { primaryColor } = useColors();
  const { isAuthenticated } = useAuth();

  const logo =
    themeMode === 'light'
      ? settings?.ui?.theming?.logo?.light
      : settings?.ui?.theming?.logo?.dark;

  // Memoized Metadata
  const metadata = useMemo(
    () => ({
      name: settings?.ui?.appName || 'Twin Protocol',
      description: settings?.ui?.organizationName || 'Twin Protocol',
      url: import.meta.env.VITE_SITE_URL,
      icons: [logo],
    }),
    [settings, logo]
  );

  // Memoized Networks
  const networks = useMemo<[AppKitNetwork, ...AppKitNetwork[]]>(
    () => (devMode ? [polygonAmoy, mainnet] : [mainnet]),
    []
  );

  // Memoized Ethers Adapter
  const ethersAdapter = useMemo(() => new EthersAdapter(), []);

  // Initialize AppKit only once
  useMemo(
    () =>
      createAppKit({
        adapters: [ethersAdapter],
        networks,
        metadata,
        projectId,
        features: {
          analytics: true,
          email: false,
          socials: [],
        },
      }),
    [ethersAdapter, networks, metadata]
  );

  const { setThemeMode, setThemeVariables } = useAppKitTheme();
  const { isConnected, address } = useAppKitAccount();
  const { disconnect } = useDisconnect();
  const { chainId } = useAppKitNetworkCore();
  const { walletProvider } = useAppKitProvider<Provider>('eip155');
  const [ethBalance, setEthBalance] = useState<string | null>(null);
  const [wrongNetwork, setWrongNetwork] = useState<boolean>(false);

  // Memoized ethersProvider
  const ethersProvider = useMemo(() => {
    if (!isConnected || !walletProvider || wrongNetwork || !isAuthenticated)
      return null;
    console.log('🔄 Updating ethersProvider with chainId:', chainId);
    try {
      return new BrowserProvider(walletProvider, chainId);
    } catch (error) {
      console.error('Error creating ethersProvider:', error);
      return null;
    }
  }, [isConnected, walletProvider, wrongNetwork, chainId, isAuthenticated]);

  useEffect(() => {
    setThemeMode(themeMode === 'light' ? 'light' : 'dark');
    setThemeVariables({
      '--w3m-accent': primaryColor,
      '--w3m-border-radius-master': '0.15rem',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColor, themeMode]);

  useEffect(() => {
    if (!walletProvider || !address || !isAuthenticated || !isConnected) return;
    let isMounted = true;
    const getEthBalance = async () => {
      try {
        const provider = new BrowserProvider(walletProvider, chainId);
        const balance = await provider.getBalance(address);
        if (isMounted) setEthBalance(formatEther(balance));
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    getEthBalance();
    return () => {
      isMounted = false;
    };
  }, [address, chainId, walletProvider, isAuthenticated, isConnected]);

  useEffect(() => {
    if (chainId && !allowedChains.includes(Number(chainId))) {
      setWrongNetwork(true);
      toast.error(
        'Your wallet is connected to the wrong network, please switch the network to Ethereum.'
      );
    } else {
      setWrongNetwork(false);
    }
  }, [chainId]);

  return (
    <WalletContext.Provider
      value={{
        ethBalance,
        disconnect,
        ethersProvider,
        wrongNetwork,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export { WalletContext };

export default WalletWrapper;
