import { useMemo } from 'react';

import Lottie from 'react-lottie';
import { useSearchParams } from 'react-router-dom';

import failedIcon from '@/assets/lottie/failed.json';
import successIcon from '@/assets/lottie/success.json';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from '@/components/ui/dialog';
import { paymentMessages } from '@/constants/payment-status-constants';

enum statusHead {
  success = 'Payment Successful',
  failure = 'Payment Failed',
}

export default function PaymentStatusDialog({
  transactionStatus,
  isTransactionDialogOpen,
  onCloseTransactionDialog,
  errorMessage,
  // cart,
  product,
  // productLoading,
  // productPrice
}) {
  const [searchParam] = useSearchParams();
  const type = Number(searchParam.get('type'));
  // const { id: productId } = useParams();

  const defaultOptions = useMemo(
    () => ({
      loop: false,
      autoplay: true,
      animationData: transactionStatus === 'success' ? successIcon : failedIcon,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    }),
    [transactionStatus]
  );

  // const { product } = useProductDetails({
  //   productId: productId || '',
  //   type: type,
  //    file: 'paymentStatusDialog'
  // });

  const selectedMessage = paymentMessages.find((msg) => msg.type === type);

  return (
    <Dialog
      open={isTransactionDialogOpen}
      onOpenChange={onCloseTransactionDialog}
    >
      <DialogContent className="max-w-sm sm:max-w-md">
        <DialogHeader>
          <h3 className="">
            {transactionStatus === 'failure'
              ? 'Payment Issue'
              : 'Purchase Successful'}
          </h3>
        </DialogHeader>

        <div className="flex flex-col items-center gap-10 w-full">
          <Lottie options={defaultOptions} height={240} width={240} />
          <h2 className="text-center">{statusHead[transactionStatus]}</h2>
          <DialogDescription className="text-center ">
            {transactionStatus === 'failure' ? (
              <>
                Payment was declined or is still pending. Please try again. If
                any amount was deducted, it will be automatically refunded to
                you.
                <div className="mt-3 text-red-500">{errorMessage}</div>
              </>
            ) : (
              <>
                {typeof selectedMessage?.success === 'function'
                  ? selectedMessage?.success(product?.name)
                  : selectedMessage?.success}
                <br />
                {selectedMessage?.details}
              </>
            )}
          </DialogDescription>
        </div>
        <Button onClick={onCloseTransactionDialog} className="w-full">
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
}
