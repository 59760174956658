import api from '@/services';

export const apiGetStripeClientSecret = async (payload: {
  productId: string;
  type: number | null;
  twinId?: string;
}) => {
  return await api.post('/payment/stripe/checkout', payload);
};

export const apiGetStripeStatus = async (payload: {
  productId: string;
  sessionId: string;
  
}) => {
  return await api.get('/payment/stripe/status', { params: payload });
};

export const apiGetPaymentStatus = async (payload: {
  productId: string;
  sessionId: string;
  type: number;
  twinId?: string
}) => {
  return await api.get('/payment/stripe/status', { params: payload });
};

export const apiGetProductDetails = async (payload: {
  productId: string;
  type: number;
  twinId?: string;
}) => {
  return await api.get('/app/product', { params: payload });
};

export const apiGetPaymentMethods = async () => {
  return await api.get('/payment/stripe/payment-methods');
};

export const apiResumeSubscription = async (payload: { twinId: string }) => {
  return await api.post('/payment/stripe/resume-subscription', payload);
};
