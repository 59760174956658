import twinToken from '@/assets/icons/twin-token.svg';
import FET_TOKEN_ABI from '@/constants/web3-constants/FETTokenABI.json';
import TWIN_TOKEN_ABI from '@/constants/web3-constants/TWINTokenABI.json';

export const web3Config = {
  TWIN: {
    name: 'Twin Protocol',
    symbol: 'TWIN',
    ABI: TWIN_TOKEN_ABI,
    chains: {
      80002: import.meta.env.VITE_POLYGON_TESTNET_TWIN,
      1: import.meta.env.VITE_ETH_MAINNET_TWIN,
      8453: import.meta.env.VITE_BASE_GOERLI_TWIN,
    },
    icon: twinToken,
    buyUrl: `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${import.meta.env.VITE_ETH_MAINNET_TWIN}`,
  },
  FET: {
    name: 'Artificial Superintelligence Alliance',
    ABI: FET_TOKEN_ABI,
    symbol: 'FET',
    chains: {
      80002: import.meta.env.VITE_POLYGON_TESTNET_FET,
      1: import.meta.env.VITE_ETH_MAINNET_FET,
      8453: import.meta.env.VITE_BASE_GOERLI_FET,
    },
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png',
    buyUrl: `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${import.meta.env.VITE_ETH_MAINNET_FET}`,
  },
};
