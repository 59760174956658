import { Fragment } from 'react';

import { ExternalLink, Unplug, Wallet } from 'lucide-react';
import millify from 'millify';

import { Alert } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Skeleton } from '@/components/ui/skeleton';
import { web3Config } from '@/configs/web3-config';
import { useWallet } from '@/lib/hooks/web3/useWallet';
import TwinCoin from '@/pages/profile/components/TwinCoin';

// import { TextToolTip } from '../TextToolTip';

export default function WalletOverview({
  isOpen,
  setIsOpen,
  errorMessage,
  balanceLoading,
  tokenBalance,
  otherTokenBalance,
  handleDisconnect,
}) {
  // const { caipNetwork } = useAppKitNetworkCore();
  // const { address } = useAppKitAccount();

  // const handleCopy = useCallback(() => {
  //   navigator.clipboard.writeText(address);
  //   toast.success('Wallet address copied to clipboard!');
  // }, [address]);
  const { wrongNetwork } = useWallet();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-w-sm sm:max-w-md">
        {errorMessage ||
          (wrongNetwork && (
            <Alert variant="destructive">
              {errorMessage
                ? errorMessage
                : 'Your wallet is connected to the wrong network, please switch the network to Ethereum.'}
            </Alert>
          ))}
        <h3 className="flex items-center gap-2">
          <Wallet /> Twin Wallet
        </h3>
        <Card className="flex flex-col space-y-4 my-2 bg-secondary/20 px-3.5 py-3">
          <div className="space-y-4">
            <div className="flex items-center justify-between w-full">
              <div className="space-y-2">
                <div className="flex items-center gap-4">
                  <p className="text-sm">TWIN Token Balance</p>
                  <a
                    href={web3Config.TWIN.buyUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-1 text-xs underline hover:text-primary transition underline-offset-1"
                  >
                    Buy TWIN <ExternalLink size={16} />
                  </a>
                </div>
                <h2 className="text-primary">
                  {!balanceLoading ? (
                    millify(Number(tokenBalance), { precision: 3 }) + ' TWIN'
                  ) : (
                    <Skeleton className="w-36 h-6 mt-2" />
                  )}
                </h2>
              </div>
              <TwinCoin tokenIcon size={12} />
            </div>
            {otherTokenBalance?.length > 0 &&
              otherTokenBalance?.map((token) => (
                <Fragment key={token?.name}>
                  <hr className="border-t border-input border-dotted" />
                  <div className="flex items-center justify-between w-full">
                    <div className="space-y-2">
                      <div className="flex items-center gap-4">
                        <p className="text-sm">{token?.name} Token Balance</p>
                        <a
                          href={web3Config[token.name].buyUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-1 text-xs underline hover:text-primary transition underline-offset-1"
                        >
                          Buy {token.name} <ExternalLink size={16} />
                        </a>
                      </div>
                      <h4 className="text-primary">
                        {!balanceLoading ? (
                          millify(Number(token.balance), { precision: 3 }) +
                          ' ' +
                          token.name
                        ) : (
                          <Skeleton className="w-36 h-6 mt-2" />
                        )}
                      </h4>
                    </div>
                    <img
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png"
                      alt=""
                      className="w-12 h-12 rounded-full"
                    />
                  </div>
                </Fragment>
              ))}
          </div>
          <hr className="border-t border-input border-dotted" />
          <div className="flex justify-center gap-2 items-end">
            {/* <div className="w-full">
              <p className="text-sm space-y-2 w-full">Connected Network</p>
              <h5>{caipNetwork.name || 'Unknown Network'}</h5>
            </div> */}
            {/* <div className="flex flex-col justify-center items-end h-full w-full text-sm">
              <p>Wallet Address</p>
              <div className="flex items-center gap-2">
                <h5>{address?.slice(0, 4) + '....' + address?.slice(-4)}</h5>
                <Button
                  onClick={handleCopy}
                  variant="ghost"
                  className="h-max w-max hover:bg-transparent"
                  size="icon"
                >
                  <TextToolTip label="Copy wallet address">
                    <Copy size={16} />
                  </TextToolTip>
                </Button>
              </div>
            </div> */}
            <appkit-button size="sm" label="Account" />
          </div>
        </Card>
        <div className="flex justify-between gap-2 w-full">
          <appkit-network-button />
          <Button variant="destructive" onClick={handleDisconnect}>
            Disconnect Wallet <Unplug size={20} className="ml-2" />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
