import { useSettingsStore } from '@/store';

export default function useColors() {
  const { settings, themeMode } = useSettingsStore();

  const colors = settings.ui.theming.colors;

  const primaryColor = colors.primary[themeMode as 'light' | 'dark'];
  const secondaryColor = colors.secondary[themeMode as 'light' | 'dark'];
  const primaryForegroundColor =
    themeMode === 'light'
      ? colors.primary.foregroundLight
      : colors.primary.foregroundDark;
  const secondaryForegroundColor =
    themeMode === 'light'
      ? colors.secondary.foregroundLight
      : colors.secondary.foregroundDark;

  return {
    primaryColor,
    secondaryColor,
    primaryForegroundColor,
    secondaryForegroundColor,
  };
}
