import { memo } from 'react';

import { useAppKit, useAppKitAccount } from '@reown/appkit/react';
import millify from 'millify';
import { AiOutlinePlus } from 'react-icons/ai';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { web3Config } from '@/configs/web3-config';
import TwinCoin from '@/pages/profile/components/TwinCoin';

const WalletConnectButton = memo(
  ({
    openWeb3Modal,
    tokenBalance,
    balanceLoading,
  }: {
    openWeb3Modal: () => void;
    tokenBalance: number;
    balanceLoading: boolean;
  }) => {
    const { open } = useAppKit();
    const { isConnected } = useAppKitAccount();

    return (
      <>
        {!isConnected ? (
          <Button
            onClick={() => open()}
            size="sm"
            className="w-full rounded-full px-3 h-8"
            data-testid="wallet-connect-btn"
          >
            Connect Wallet
          </Button>
        ) : (
          <>
            {!balanceLoading ? (
              <div
                onClick={openWeb3Modal}
                className="flex w-full justify-between items-center px-2 py-1 gap-1 sm:gap-2 rounded-full bg-secondary text-secondary-foreground cursor-pointer"
              >
                <div className="flex gap-1 items-center justify-between sm:gap-2">
                  <TwinCoin tokenIcon size={6} />
                  <div className="flex gap-1 font-semibold text-sm pr-1">
                    {millify(tokenBalance, { precision: 3 }) || '0'} {''}
                  </div>
                </div>
                <a
                  href={web3Config.TWIN.buyUrl}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="buy-twin-token-link"
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="!h-6 !w-6 p-0 px-1 rounded-full"
                    data-testid="buy-twin-token-button"
                  >
                    <AiOutlinePlus />
                  </Button>
                </a>
              </div>
            ) : (
              <Skeleton className="w-20 h-6" />
            )}
          </>
        )}
      </>
    );
  }
);

export default WalletConnectButton;
