export type PaymentMessage = {
  type: number;
  success: string | ((data?: any) => string);
  details: string | ((data?: any) => string);
  buttonText: string;
  buttonLink: string | ((data?: any) => string);
};

export const paymentMessages: PaymentMessage[] = [
  {
    type: 900,
    success: (productName: string) => `Thank you for purchasing ${productName}`,
    details: 'Points have been successfully added to your Twin Vault.',
    buttonText: 'Back to Vault',
    buttonLink: '/profile?tab=vault',
  },
  {
    type: 901,
    success: (productName: string) => `Thank you for purchasing ${productName}`,
    details: 'Your membership has been successfully activated.',
    buttonText: 'Go to Membership',
    buttonLink: '/profile?tab=membership',
  },
  {
    type: 902,
    success: (productName: string) => `Thank you for purchasing ${productName}`,
    details:
      'Your Twin plan is now active. Click on the button below for further configuration of your twin.',
    buttonText: 'Update Twin',
    buttonLink: '/my-twin/update',
  },
  {
    type: 903,
    success: (productName) => `Thank you for purchasing ${productName}`,
    details: 'Your subscription is now activated and ready for use.',
    buttonText: 'Back to Chat',
    buttonLink: (twinId: string) => `/chat/${twinId}`,
  },
];
