import { CircleAlert } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { web3Config } from '@/configs/web3-config';

export const BuyTokensContent = ({ token }) => (
  <>
    <div className="text-left text-sm space-y-4 w-full">
      <h5 className="text-destructive">
        You do not have enough {token} Tokens to complete this purchase.
      </h5>
      <p className="mb-2">Steps to buy {token} Tokens:</p>
      <ul className=" list-outside text-muted-foreground">
        <li>
          1. Click on the “Buy {token} Now” button to be redirected to Uniswap.
        </li>
        <li>2. Choose the {token} token in the swap menu.</li>
        <li>3. Input the desired amount and review the swap details.</li>
        <li>4. Approve and confirm the transaction.</li>
        <li>5. Wait for the transaction to process.</li>
        <li>6. Receive {token} tokens in your wallet.</li>
      </ul>
    </div>
    <a
      href={web3Config[token].buyUrl}
      target="_blank"
      rel="noreferrer"
      className="w-full"
    >
      <Button data-testid="buy-point-buy-now-button" className="w-full">
        Buy {token} Now
      </Button>
    </a>
  </>
);

export const BuyEthereumContent = () => (
  <div className="flex flex-col text-left text-sm w-full">
    <h5 className="text-destructive mb-4">
      Insufficient Ethereum to calculate the gas fee for this transaction.
    </h5>
    <p>
      To carry out transactions on the Polygon network, gas fees must be paid
      using Ethereum. To cover these fees, you'll need to buy Ethereum and move
      it to your wallet.
    </p>
    <br />
    <p className="mb-2">Follow the steps below to acquire Ethereum:</p>
    <ul className="list-outside text-muted-foreground">
      <li>1. Choose a Cryptocurrency Exchange</li>
      <li>2. Create an Account (if you don’t have one)</li>
      <li>3. Deposit Funds</li>
      <li>4. Buy Ethereum</li>
      <li>5. Transfer Ethereum to Your Wallet</li>
      <li>6. Verify the Balance</li>
    </ul>
  </div>
);

export const NoBalanceModal = ({ type, token }) => {
  return (
    <div className="flex flex-col items-center gap-4 text-center">
      <CircleAlert size={72} className="text-destructive" />
      {type === 'token' && <BuyTokensContent token={token} />}
      {type === 'crypto' && <BuyEthereumContent />}
    </div>
  );
};
