import { Link } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { PATHS } from '@/constants/page-paths';
import { useGetProfile } from '@/lib/hooks/api/profile.hook';
import { useSettingsStore, useTokenStore, useUserStore } from '@/store';
import { useAppConfig } from '../layouts/AppConfigProvider';
import { Logout } from '../shared/Logout';
import { Web3Wallet } from '../shared/web3-wallet/web3-wallet';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Switch } from '../ui/switch';

interface ProfileMenuList {
  title: string;
  path: string;
}

const profileMenus: ProfileMenuList[] = [
  { title: 'Profile', path: `${PATHS.PROFILE}?tab=account` },
  { title: 'Settings', path: `${PATHS.PROFILE}?tab=settings` },
  { title: 'Vault', path: `${PATHS.PROFILE}?tab=vault` },
];

export function ProfileDropdown() {
  const { user } = useUserStore();
  const { token } = useTokenStore();
  const { data } = useGetProfile({
    enabled: !!token,
  });

  const { colorScheme } = useAppConfig();

  const profile = data?.data?.data ?? user;

  const { setThemeMode, themeMode } = useSettingsStore();

  const toggleTheme = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <div
          role="button"
          className="flex items-center h-full rounded-none gap-3 hover:bg-secondary transition hover:text-secondary-foreground px-1 md:px-2 -mr-1 md:-mr-2"
          data-testid="profile-dropdown-trigger"
        >
          {profile?.firstName && (
            <h5 className="capitalize hidden md:block">
              {profile?.firstName as string}
            </h5>
          )}

          <Avatar>
            <AvatarImage
              src={profile?.userImage as string}
              alt="profile"
              className="w-full h-full object-cover"
            />
            <AvatarFallback className="text-2xl uppercase">
              {profile?.name?.[0] as string}
            </AvatarFallback>
          </Avatar>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="min-w-[200px]" align="end">
        <DropdownMenuLabel>
          <p>{profile?.name as string}</p>
          <p className="text-muted-foreground text-sm">
            {profile?.email as string}
          </p>
          <DropdownMenuSeparator />
        </DropdownMenuLabel>
        <DropdownMenuItem
          className="sm:hidden"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Web3Wallet />
        </DropdownMenuItem>
        <DropdownMenuSeparator className="sm:hidden" />
        <DropdownMenuGroup>
          {profileMenus.map((menu, i) => (
            <Link to={menu.path} key={i}>
              <DropdownMenuItem
                className="w-full cursor-pointer"
                data-testid="profile-menu-dropdown-item"
              >
                {menu.title}
              </DropdownMenuItem>
            </Link>
          ))}
        </DropdownMenuGroup>

        {colorScheme === 'auto' && (
          <>
            <DropdownMenuItem
              className="w-full flex justify-between items-center cursor-pointer"
              onClick={() => toggleTheme()}
              data-testid="mode-switcher"
            >
              <span>Dark Mode</span>
              <Switch
                checked={themeMode === 'dark'}
                onCheckedChange={toggleTheme}
              />
            </DropdownMenuItem>
          </>
        )}

        <DropdownMenuSeparator />
        {/* <Link to={PATHS.MY_TWIN}>
          <DropdownMenuItem className="w-full cursor-pointer">
            My Twin
          </DropdownMenuItem>
        </Link> */}
        <Link to={PATHS.FEEDBACK}>
          <DropdownMenuItem
            className="w-full cursor-pointer"
            data-testid="help-feedback-form-button"
          >
            Help & Feedback
          </DropdownMenuItem>
        </Link>

        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="w-full cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
          }}
          data-testid="logout-profile-dropdown"
        >
          <Logout>
            <span className="w-full">Log out</span>
          </Logout>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
