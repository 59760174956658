import { useCallback } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { APP_CONFIG } from '@/constants/app-config';
import { PATHS } from '@/constants/page-paths';
import {
  apiForgotPassword,
  apiRegister,
  apiResetPassword,
  apiSignIn,
  apiSignOut,
  apiVerifyEmail,
} from '@/services/auth.api';
import { useTokenStore, useUserStore } from '@/store';
import { UseSignOutProps } from '@/types/others';
import useUrlParams from './useParams';

export const useAuth = () => {
  const { token, setToken } = useTokenStore();
  const { setAuthenticated, setUser, isAuthenticated } = useUserStore();
  const navigate = useNavigate();
  const params = useUrlParams();

  const signInUser = useCallback(
    (data: Record<string, unknown>) => {
      const { token, is2faEnabled, ...userInfo } = data || {};
      if (!is2faEnabled) {
        setToken(token as string);
        setAuthenticated(true);
        setUser(userInfo);
        const persistedRedirectUrl = localStorage.getItem('redirectUrl');
        const redirectUrl =
          params.get(APP_CONFIG.REDIRECT_URL_KEY) || persistedRedirectUrl;
        navigate(redirectUrl ? redirectUrl : APP_CONFIG.authenticatedEntryPath);
      }
    },
    [navigate, params, setAuthenticated, setToken, setUser]
  );

  const {
    mutateAsync: signIn,
    isLoading: isSignInPending,
    ...singInData
  } = useMutation({
    mutationFn: apiSignIn,
    onSuccess: (res) => {
      // invalidateQuery(['twin']);
      signInUser(res?.data?.data);
    },
  });

  const {
    mutateAsync: logOut,
    isLoading: isSingOutPending,
    ...signOutData
  } = useMutation({
    mutationFn: apiSignOut,
    onSuccess: () => {
      setToken(null);
    },
  });
  const signOut = useCallback(
    async ({ apiCall = true, state, redirect }: UseSignOutProps = {}) => {
      localStorage.removeItem('redirectUrl');
      if (apiCall) {
        await logOut();
      } else {
        setToken(null);
      }
      setAuthenticated(false);
      setUser(null);
      navigate(redirect ?? PATHS.SIGN_IN, { replace: true, state });
    },
    [logOut, navigate, setAuthenticated, setToken, setUser]
  );

  const registerUser = useMutation({
    mutationFn: apiRegister,
  });

  const {
    mutateAsync: verifyEmail,
    isLoading: isVerifyPending,
    ...verifyData
  } = useMutation({
    mutationFn: apiVerifyEmail,
  });

  const forgotPassword = useMutation({
    mutationFn: apiForgotPassword,
  });

  const resetPassword = useMutation({
    mutationFn: apiResetPassword,
  });

  return {
    singInData,
    signIn,
    isSignInPending,
    isAuthenticated,
    token,
    isSingOutPending,
    signOut,
    signOutData,
    registerUser,
    verifyEmail,
    isVerifyPending,
    verifyData,
    forgotPassword,
    resetPassword,
    signInUser,
  };
};
