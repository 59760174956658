// prettier-ignore

import { useEffect, useRef, useCallback as x1, useEffect as x2, useMemo as x3, useState as x4 } from 'react';

import y1 from 'canvas-confetti';
import { motion as y2 } from 'framer-motion';

import {
  Dialog as A1,
  DialogContent as A2,
  DialogTitle as A5,
  DialogDescription as A6,
} from '@/components/ui/dialog';
import { Meteors as A3 } from '@/components/ui/mtr';
import { SparklesText as A4 } from '@/components/ui/sparkle-text';
import z2 from './chat-2.mp3';
import z1 from './default-audio.mp3';

const A = () => {
  const ads = [z1, z2];
  const crrAd = ads[Math.floor(Math.random() * ads.length)];
  const cr1 = atob('U2FuZGh5YQ==');
  const cr2 = atob('VmlzaGFs');
  const crh = atob('RGVzaWduZWQgJiBEZXZlbG9wZWQgYnk=');

  const [b1, b2] = x4(false);
  const [b3, b4] = x4(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (b1) {
        audioRef.current.play().catch((error) => {
          console.error('Audio autoplay blocked:', error);
        });
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [b1]);

  x2(() => {
    const c1 = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      's',
      'v',
    ];
    let c2 = [];
    const c3 = (c4) => {
      c2.push(c4.key);
      c2 = c2.slice(-c1.length);
      if (c2.join('') === c1.join('')) b2(true);
    };
    window.addEventListener('keydown', c3);
    return () => window.removeEventListener('keydown', c3);
  }, []);
  x2(() => {
    if (!Object.prototype.hasOwnProperty.call(window, 'sv')) {
      Object.defineProperty(window, 'sv', {
        get() {
          console.log(
            '%c🚀 Easter Egg Activated!',
            'color: #ff4b5c; font-size: 16px;'
          );
          b2(true);
        },
        configurable: true,
      });
    }
  }, []);
  const d1 = x1(() => {
    const e1 = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8'],
    };
    const e2 = () => {
      y1({ ...e1, particleCount: 40, scalar: 1.2, shapes: ['star'] });
      y1({ ...e1, particleCount: 10, scalar: 0.75, shapes: ['circle'] });
    };
    [0, 100, 200].forEach((e3) => setTimeout(e2, e3));
  }, []);
  const d2 = x1(() => {
    const e4 = Date.now() + 3000;
    const e5 = ['#a786ff', '#fd8bbc', '#eca184', '#f8deb1'];
    const e6 = () => {
      if (Date.now() > e4) return;
      y1({
        particleCount: 2,
        angle: 60,
        spread: 55,
        startVelocity: 60,
        origin: { x: 0, y: 0.5 },
        colors: e5,
      });
      y1({
        particleCount: 2,
        angle: 120,
        spread: 55,
        startVelocity: 60,
        origin: { x: 1, y: 0.5 },
        colors: e5,
      });
      requestAnimationFrame(e6);
    };
    e6();
  }, []);
  const d3 = x1(() => {
    const e7 = 5000;
    const e8 = Date.now() + e7;
    const e9 = { startVelocity: 30, spread: 360, ticks: 60 };
    const e10 = (f1, f2) => Math.random() * (f2 - f1) + f1;
    const e11 = window.setInterval(() => {
      const f3 = e8 - Date.now();
      if (f3 <= 0) return clearInterval(e11);
      y1({
        ...e9,
        particleCount: 50 * (f3 / e7),
        origin: { x: e10(0.1, 0.3), y: Math.random() - 0.2 },
      });
      y1({
        ...e9,
        particleCount: 50 * (f3 / e7),
        origin: { x: e10(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  }, []);
  const d4 = x1(() => {
    d1();
    d2();
    d3();
  }, [d1, d2, d3]);
  x2(() => {
    if (b1) {
      setTimeout(d2, 2000);
      setTimeout(d1, 3000);
      setTimeout(d3, 5000);
    }
  }, [d3, b1, d2, d1]);
  const d5 = x3(
    () => (
      <div
        onMouseEnter={() => {
          b4(true);
          d4();
        }}
        onMouseLeave={() => b4(false)}
        className="relative z-10"
      >
        <a
          className="hover:text-pink-500 hover:font-bold hover:drop-shadow-[0_0_10px_rgba(255,105,180,0.8)] duration-500 transition-all"
          href="https://www.linkedin.com/in/ACoAADUoF3QBTIr7dUSuIrauGcSQ8S1yuJI5hio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {cr1}
        </a>{' '}
        &{' '}
        <a
          className="hover:text-purple-500 hover:font-bold hover:drop-shadow-[0_0_10px_rgba(128,0,128,0.8)] duration-500 transition-all"
          href="https://www.linkedin.com/in/ACoAABX2sMcBivrmqNHYL0h52qNtX6oqmSEzhJ4/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {cr2}
        </a>
      </div>
    ),
    []
  );
  return (
    <>
      <audio
        ref={audioRef}
        className="absolute hidden"
        src={crrAd}
        loop
        playsInline
      />
      <A1 open={b1} onOpenChange={b2}>
        <A2 className="bg-transparent border-none rounded-2xl shadow-lg max-w-lg aspect-square overflow-hidden p-0">
          <A5 className="sr-only">''</A5>
          <A6 className="sr-only">''</A6>
          <y2.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="relative w-full h-full flex flex-col justify-center items-center overflow-hidden bg-cover">
              <div
                style={{
                  backgroundImage: `url(https://lh3.googleusercontent.com/pw/AP1GczMlWOOvyHnybb4FAAoDsAnB6Fc3YQCowlbBI66zrmPH2pKisfIY6KuKQvEgalkcll8Q5qeKFoATxnqVQAEED_Bn7ZtsupNS9s0L3iGaeOcgTXA-vrk=w2400)`,
                  filter: b3
                    ? 'blur(0px) brightness(100%)'
                    : 'blur(20px) brightness(40%)',
                  transition: 'filter 0.5s ease-in-out',
                }}
                className="absolute inset-0 bg-center bg-cover"
              />

              <A3 />
              <h6 className="mb-2 text-white relative z-10">🚀 {crh} ✨</h6>
              <A4
                text={d5}
                className="!text-white text-4xl lg:text-5xl text-center font-black relative z-10"
              />
            </div>
          </y2.div>
        </A2>
      </A1>
    </>
  );
};
// eslint-disable-next-line react-refresh/only-export-components
export default A;
