import { useCallback } from 'react';

import { formatEther } from 'ethers';

import { useWallet } from './useWallet';

const TWIN_WALLET_ADDRESS = import.meta.env.VITE_TWIN_WALLET_ADDRESS;

export default function useGasFee() {
  const { ethersProvider, ethBalance } = useWallet();

  const getGasPrice = useCallback(async () => {
    if (!ethersProvider) return;
    try {
      const feeData = await ethersProvider.getFeeData();
      return feeData.gasPrice;
    } catch (e) {
      console.error('Gas Price Error:', e);
    }
  }, [ethersProvider]);

  const getEstimateGas = useCallback(
    async (amount) => {
      if (!ethersProvider) return;
      try {
        return await ethersProvider.estimateGas({
          to: TWIN_WALLET_ADDRESS,
          value: amount,
        });
      } catch (e) {
        console.error('Estimate Gas Error:', e);
      }
    },
    [ethersProvider]
  );

  const checkGasFee = useCallback(
    async (amountInEther: bigint) => {
      if (!ethBalance || !ethersProvider) return false;
      try {
        const [gasEstimate, gasPrice] = await Promise.all([
          getEstimateGas(amountInEther),
          getGasPrice(),
        ]);

        if (!gasEstimate || !gasPrice) return false;

        const gasCost = gasEstimate * gasPrice;

        const result = Number(ethBalance) < Number(formatEther(gasCost));
        return result;
      } catch (e) {
        console.error('Check Gas Fee Error:', e);
        return false;
      }
    },
    [ethBalance, ethersProvider, getEstimateGas, getGasPrice]
  );

  return { checkGasFee, getGasPrice };
}
