import { useEffect, useState } from 'react';

import { RefreshCcw, WifiOff } from 'lucide-react';

import OfflineProvider from '@/assets/icons/cards/general-card/cr';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';

export const OfflineHandler = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  const [isOffline, setIsOffline] = useState(() => !navigator.onLine);

  const onOpenModal = () => {
    if (isOffline) {
      return;
    }
    setIsOffline(!isOffline);
  };
  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
    };

    const handleOnline = () => {
      setIsOffline(false);
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    <>
      <Dialog open={isOffline} onOpenChange={onOpenModal}>
        <DialogContent
          className="flex flex-col items-center justify-center max-w-xl featured text-primary-foreground pt-6 border-none"
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <WifiOff className="w-16 h-16 mb-4 text-primary-foreground animate-pulse" />
          <h1 className="text-3xl font-bold">You’re Offline</h1>
          <p className="text-lg text-gray-200">
            Check your internet connection and try again.
          </p>
          <Button onClick={handleRetry}>
            <RefreshCcw className="mr-2 w-5 h-5" />
            Try Again
          </Button>
        </DialogContent>
      </Dialog>
      <OfflineProvider />
    </>
  );
};
