import { HelmetProvider } from 'react-helmet-async';

import WalletWrapper from '@/components/layouts/WalletWrapper';
import Pages from '@/pages/Pages';

function App() {
  return (
    <HelmetProvider>
      <WalletWrapper>
        <Pages />
      </WalletWrapper>
    </HelmetProvider>
  );
}

export default App;
