import millify from 'millify';

import { ContentLoader } from '@/components/loaders/content-loader';
import { Alert } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { web3Config } from '@/configs/web3-config';
import { NoBalanceModal } from './NoBalanceModal';

export default function PaymentConfirmationDialog({
  showConfirmation,
  handleClose,
  isLoading,
  balanceError,
  errorMessage,
  tokenBalance,
  otherTokenBalance,
  cart,
  isLoadingTransaction,
  handleCheckout,
  isTokenCheckoutLoading,
  product,
  productLoading,
  productPrice,
}) {
  return (
    <Dialog open={showConfirmation} onOpenChange={handleClose}>
      <DialogContent
        className="sm:max-w-sm"
        onInteractOutside={(e) => {
          if (isTokenCheckoutLoading || isLoadingTransaction) {
            e.preventDefault();
          }
        }}
      >
        {isLoading || productLoading ? (
          <ContentLoader />
        ) : balanceError.show ? (
          <NoBalanceModal type={balanceError.type} token={cart?.token} />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Pay with {cart?.token} Token</DialogTitle>
            </DialogHeader>
            {errorMessage && (
              <Alert variant="destructive" className="text-sm">
                {errorMessage}
              </Alert>
            )}
            <div className="flex items-center justify-between gap-2 px-4 py-2 text-sm border rounded-lg bg-muted/60">
              <div>
                <p className="text-xs">{cart?.token} Token balance</p>
                <span>
                  {cart?.token === 'TWIN'
                    ? millify(Number(tokenBalance), { precision: 3 })
                    : ((otherTokenBalance?.length > 0 &&
                        millify(Number(otherTokenBalance[0].balance), {
                          precision: 3,
                        })) ??
                      0)}
                </span>
              </div>
              <img
                src={web3Config[cart?.token]?.icon}
                alt=""
                className="w-10 h-10 rounded-full"
              />
            </div>

            <div className="flex flex-col rounded-lg border text-sm bg-muted/70 p-4">
              <div className="flex justify-center border-b-2 border-muted rounded-t-lg">
                <h4>{product?.name}</h4>
              </div>
              <div className=" flex flex-col gap-3 py-3">
                {/* <div className="flex justify-between">
                  <p>Price:</p>
                  <span className="">
                    {cart?.amount} {cart?.token}
                  </span>
                </div>
                <div className="flex justify-between">
                  <p>{cartTitle(cart?.type)} Buying:</p>
                  <h6 className="text-success">{cart?.name}</h6>
                </div> */}
                {/* <hr className="border-t-2 border-dashed border-input" /> */}
                <div className="flex flex-col items-center gap-1">
                  <p className="text-muted-foreground">
                    Total Amount to be paid
                  </p>
                  <h2 className="text-success">
                    {productPrice} {cart?.token}
                  </h2>
                </div>
                {/* <div className="flex justify-between">
                  <p className="text-base text-muted-foreground">
                    Total Amount to be paid:
                  </p>
                  <h5 className="text-primary">
                    {cart?.amount} {cart?.token}
                  </h5>
                </div> */}
              </div>
            </div>
            <p className="text-xs text-center">
              ⚠️ The payable token amount may fluctuate due to market
              conditions.
            </p>
            {isLoadingTransaction && (
              <p className="text-sm text-amber-500 text-center">
                Please confirm the transaction in your wallet
              </p>
            )}
            <div className="space-y-2">
              <Button
                onClick={handleCheckout}
                type="button"
                loading={isTokenCheckoutLoading || isLoadingTransaction}
                className="w-full"
              >
                {isTokenCheckoutLoading || isLoadingTransaction
                  ? 'Processing...'
                  : 'Pay'}
              </Button>
              <Button
                variant="outline"
                onClick={handleClose}
                className="w-full"
                disabled={isTokenCheckoutLoading || isLoadingTransaction}
              >
                Decline
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
