import { useContext } from 'react';

import {
  WalletContext,
  WalletContextType,
} from '@/components/layouts/WalletWrapper';

export const useWallet = (): WalletContextType => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletWrapper');
  }
  return context;
};
