import { StateCreator } from 'zustand';

interface CartItem {
  productId: string;
  type: 900 | 901 | 902 | 903;
  token: 'FET' | 'TWIN';
}

export interface Web3WalletSlice {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showConfirmation: boolean;
  setShowConfirmation: (isOpen: boolean) => void;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  walletAddress: string;
  setWalletAddress: (walletAddress: string) => void;
  tokenBalance: string;
  setTokenBalance: (tokenBalance: string) => void;
  otherTokenBalance: { name: string; balance: string }[] | null;
  setOtherTokenBalance: (
    otherTokenBalance: { name: string; balance: string }[] | null
  ) => void;
  cart: CartItem | null;
  setCart: (item: CartItem | null) => void;
}

export const web3WalletSlice: StateCreator<Web3WalletSlice> = (set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  showConfirmation: false,
  setShowConfirmation: (showConfirmation: boolean) =>
    set(() => ({ showConfirmation })),
  isConnected: false,
  setIsConnected: (isConnected: boolean) => set(() => ({ isConnected })),
  walletAddress: '',
  setWalletAddress: (walletAddress: string) => set(() => ({ walletAddress })),
  tokenBalance: '',
  setTokenBalance: (tokenBalance: string) => set(() => ({ tokenBalance })),
  otherTokenBalance: null,
  setOtherTokenBalance: (
    otherTokenBalance: { name: string; balance: string }[] | null
  ) => set(() => ({ otherTokenBalance })),
  cart: null,
  setCart: (item: CartItem | null) => set(() => ({ cart: item })),
});
