import { useQuery } from 'react-query';

import { useAuth } from '@/lib/hooks/useAuth';
import { apiGetVault } from '@/services/user.api';

export function useTwinVault() {
  const { isAuthenticated } = useAuth();

  const { data: vaultResponse, ...vaultQuery } = useQuery({
    queryKey: ['vault-details'],
    queryFn: apiGetVault,
    enabled: isAuthenticated,
    // staleTime: 60_000, // Cache for 1 minute
  });

  const vaultData = vaultResponse?.data?.data ?? {};

  return {
    vaultData,

    ...vaultQuery,
  };
}
