import { useCallback, useEffect, useState } from 'react';

import { useAppKitAccount, useAppKitNetworkCore } from '@reown/appkit/react';
import { ethers } from 'ethers';
import { toast } from 'sonner';

import { web3Config } from '@/configs/web3-config';
import { useWeb3WalletStore } from '@/store';
import { useWallet } from './useWallet';

export const useFetchWalletBalance = () => {
  const [balanceLoading, setBalanceLoading] = useState(false);
  const { setTokenBalance, setOtherTokenBalance } = useWeb3WalletStore();
  const { ethersProvider, wrongNetwork } = useWallet();
  const { isConnected, address } = useAppKitAccount();
  const { chainId } = useAppKitNetworkCore();

  const fetchTokenBalance = useCallback(
    async (tokenConfig: any): Promise<string | null> => {
      if (!ethersProvider || !isConnected || !address || wrongNetwork)
        return null;

      try {
        setBalanceLoading(true);
        const signer = await ethersProvider.getSigner();

        const contractAddress = tokenConfig.chains[chainId];
        if (!contractAddress) {
          throw new Error(`No contract address found for chainId: ${chainId}`);
        }

        const contract = new ethers.Contract(
          contractAddress,
          tokenConfig.ABI,
          signer
        );

        const tokenBalance = await contract.balanceOf(address);
        const formattedBalance = ethers.formatEther(tokenBalance.toString());
        console.log(`Balance of ${tokenConfig.name}:`, formattedBalance);

        return formattedBalance;
      } catch (error: any) {
        toast.error(
          `Error fetching ${tokenConfig.symbol} balance: ${error?.shortMessage}`
        );
        console.error(`Error fetching ${tokenConfig.name} balance:`, error);
        return null;
      } finally {
        setBalanceLoading(false);
      }
    },
    [ethersProvider, isConnected, address, wrongNetwork, chainId]
  );

  // ✅ Fetch Twin Token balance
  const fetchTwinTokenBalance = useCallback(async () => {
    const twinBalance = await fetchTokenBalance(web3Config.TWIN);
    if (twinBalance) setTokenBalance(twinBalance);
  }, [fetchTokenBalance, setTokenBalance]);

  // ✅ Fetch FET Token balance
  const fetchFETTokenBalance = useCallback(async () => {
    const fetBalance = await fetchTokenBalance(web3Config.FET);
    if (fetBalance) {
      setOtherTokenBalance([{ name: 'FET', balance: fetBalance }]);
    }
  }, [fetchTokenBalance, setOtherTokenBalance]);

  // ✅ Delay execution slightly to ensure correct chainId is used
  useEffect(() => {
    if (!ethersProvider || !isConnected || !address) return;
    const timeout = setTimeout(() => {
      fetchTwinTokenBalance();
      fetchFETTokenBalance();
    }, 300);

    return () => clearTimeout(timeout);
  }, [
    fetchTwinTokenBalance,
    fetchFETTokenBalance,
    chainId,
    ethersProvider,
    isConnected,
    address,
  ]);

  return { fetchTwinTokenBalance, fetchFETTokenBalance, balanceLoading };
};
