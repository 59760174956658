import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppKitAccount, useAppKitNetworkCore } from '@reown/appkit/react';
import { Contract, ethers } from 'ethers';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { web3Config } from '@/configs/web3-config';
import { getErrorMessage } from '@/lib/helpers/get-message';
import { useAuth } from '@/lib/hooks/useAuth';
import { invalidateQuery } from '@/lib/hooks/useQuery';
import useGasFee from '@/lib/hooks/web3/useGasFee';
import { useWallet } from '@/lib/hooks/web3/useWallet';
import { useFetchWalletBalance } from '@/lib/hooks/web3/useWeb3WalletBalance';
import { apiGetProductDetails } from '@/services/payment.api';
import { apiCheckoutWithTwinToken } from '@/services/user.api';
import { useWeb3WalletStore } from '@/store';
import PaymentConfirmationDialog from './PaymentConfirmationDialog';
import PaymentStatusDialog from './PaymentStatusDialog';
import WalletConnectButton from './WalletConnectButton';
import WalletOverview from './WalletOverview';

const TWIN_WALLET_ADDRESS = import.meta.env.VITE_TWIN_WALLET_ADDRESS;

const ERROR_MESSAGES = {
  insufficientFunds: 'Not enough funds to proceed with the transaction',
  gasFeeError: 'Insufficient Ethereum to cover the gas fee.',
  lowTokenBalance: 'You do not have enough Tokens to complete this purchase.',
  transactionRejected: 'Transaction was rejected by the user.',
  walletRequest: 'Please verify the pending request in your wallet.',
  internalError: 'An internal error occurred. Try again later.',
};

export const Web3Wallet = () => {
  const {
    isOpen,
    setIsOpen,
    tokenBalance,
    otherTokenBalance,
    showConfirmation,
    setShowConfirmation,
    cart,
    setCart,
  } = useWeb3WalletStore();

  const { ethersProvider, disconnect, ethBalance } = useWallet();
  const { checkGasFee } = useGasFee();

  const { balanceLoading, fetchFETTokenBalance, fetchTwinTokenBalance } =
    useFetchWalletBalance();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState<
    'success' | 'failure' | null
  >(null);
  const [balanceError, setBalanceError] = useState({ show: false, type: null });
  const navigate = useNavigate();
  const { address } = useAppKitAccount();
  const { chainId } = useAppKitNetworkCore();
  const { isAuthenticated } = useAuth();

  const {
    data,
    isSuccess,
    isLoading: productLoading,
  } = useQuery({
    queryKey: ['fetchPackage', cart?.productId, cart?.type],
    queryFn: () =>
      apiGetProductDetails({ type: cart?.type, productId: cart?.productId }),
    enabled:
      !!address &&
      !!ethersProvider &&
      !!cart?.token &&
      !!cart?.type &&
      !!cart?.productId &&
      !isLoading &&
      !isLoadingTransaction,
    refetchInterval: 15000,
    staleTime: 15000,
    cacheTime: 15000,
  });

  const product = data?.data?.data || {};

  const productPrice = useMemo(() => {
    if (isSuccess) {
      return product?.tokenPricing[cart?.token]?.finalPrice;
    }
  }, [cart?.token, isSuccess, product?.tokenPricing]);

  const handleDisconnect = () => {
    disconnect();
    handleClose();
    toast.success('Wallet disconnected!');
  };

  useEffect(() => {
    if (!productPrice || !address || !ethersProvider) return;

    const initialize = async () => {
      setIsLoading(true);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        web3Config[cart.token].chains[chainId],
        web3Config[cart.token].ABI,
        signer
      );

      const tokenBalance = await contract.balanceOf(address);
      const amountInEther = ethers.parseEther(productPrice.toString());
      const isLessThanGasFee = await checkGasFee(amountInEther);

      if (isLessThanGasFee) {
        setBalanceError({ show: true, type: 'crypto' });
        setErrorMessage(ERROR_MESSAGES.gasFeeError);
        return;
      }

      if (tokenBalance < amountInEther) {
        setBalanceError({ show: true, type: 'token' });
        setErrorMessage(ERROR_MESSAGES.lowTokenBalance);
      }
    };

    initialize().finally(() => setIsLoading(false));

    return () => {
      setErrorMessage('');
      // setContract(null);
    };
  }, [
    cart,
    address,
    ethersProvider,
    chainId,
    ethBalance,
    checkGasFee,
    productPrice,
  ]);

  const { mutateAsync: checkoutWithToken, isLoading: isTokenCheckoutLoading } =
    useMutation(apiCheckoutWithTwinToken, {
      onSuccess: () => {
        setShowConfirmation(false);
        fetchTwinTokenBalance();
        fetchFETTokenBalance();
        invalidateQuery(['vault-details']);
      },
      onError: (err) =>
        setErrorMessage(
          getErrorMessage(err) || 'An error occurred during token checkout.'
        ),
    });

  const handleError = useCallback((error) => {
    const { code, message } = error || {};
    let errorMessage = ERROR_MESSAGES.internalError;
    if (code === 4001 || code === 'ACTION_REJECTED')
      errorMessage = ERROR_MESSAGES.transactionRejected;
    else if (code === -32002) errorMessage = ERROR_MESSAGES.walletRequest;
    else if (message?.includes('insufficient funds'))
      errorMessage = ERROR_MESSAGES.insufficientFunds;
    setErrorMessage(errorMessage);
  }, []);

  const handleCheckout = useCallback(async () => {
    if (!productPrice || !ethersProvider) return;
    setIsLoadingTransaction(true);
    setErrorMessage('');

    try {
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        web3Config[cart?.token].chains[chainId],
        web3Config[cart?.token].ABI,
        signer
      );
      const amountInEther = ethers.parseEther(productPrice.toString());
      const tx = await contract.transfer(TWIN_WALLET_ADDRESS, amountInEther);

      await tx.wait();

      await checkoutWithToken({
        productId: cart?.productId,
        trxHash: tx.hash,
        type: cart?.type,
        token: cart?.token,
      });

      setTransactionStatus('success');
    } catch (error) {
      console.log({ error });
      toast.error(error?.shortMessage || 'An error occurred during checkout.');
      setTransactionStatus('failure');
      handleError(error);
    } finally {
      setIsLoadingTransaction(false);
      setIsTransactionDialogOpen(true);
    }
  }, [
    productPrice,
    ethersProvider,
    cart?.token,
    cart?.productId,
    cart?.type,
    chainId,
    checkoutWithToken,
    handleError,
  ]);

  const handleClose = useCallback(() => {
    if (isTokenCheckoutLoading || isLoadingTransaction) {
      toast.error('Please wait for the transaction to complete.');
      return;
    }
    setIsOpen(false);
    setShowConfirmation(false);
    setCart(null);
    setErrorMessage('');
    setBalanceError({ show: false, type: null });
  }, [
    isTokenCheckoutLoading,
    isLoadingTransaction,
    setIsOpen,
    setShowConfirmation,
    setCart,
  ]);

  const onCloseTransactionDialog = useCallback(() => {
    setIsTransactionDialogOpen(false);
    setErrorMessage('');
    if (transactionStatus === 'success') {
      navigate(-1);
    }
  }, [navigate, transactionStatus]);

  return (
    <>
      {isAuthenticated && (
        <WalletConnectButton
          data-testid="web3-wallet-connect-btn"
          openWeb3Modal={() => setIsOpen(true)}
          tokenBalance={Number(tokenBalance)}
          balanceLoading={balanceLoading}
        />
      )}
      <WalletOverview
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        errorMessage={errorMessage}
        balanceLoading={balanceLoading}
        tokenBalance={tokenBalance}
        otherTokenBalance={otherTokenBalance}
        handleDisconnect={handleDisconnect}
      />
      <PaymentConfirmationDialog
        showConfirmation={showConfirmation}
        handleClose={handleClose}
        isLoading={isLoading}
        balanceError={balanceError}
        errorMessage={errorMessage}
        tokenBalance={tokenBalance}
        otherTokenBalance={otherTokenBalance}
        cart={cart}
        isLoadingTransaction={isLoadingTransaction}
        handleCheckout={handleCheckout}
        isTokenCheckoutLoading={isTokenCheckoutLoading}
        product={product}
        productLoading={productLoading}
        productPrice={productPrice}
      />

      <PaymentStatusDialog
        transactionStatus={transactionStatus}
        isTransactionDialogOpen={isTransactionDialogOpen}
        onCloseTransactionDialog={onCloseTransactionDialog}
        errorMessage={errorMessage}
        // cart={cart}
        product={product}
        // productLoading={productLoading}
        // productPrice={productPrice}
      />
    </>
  );
};
